import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { isSanityTierGrid, PageContext } from "@util/types";
import { Component, Hero, TierGrid } from "@components";
import FooterSections from "@shared/footer/footerSections";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

export default function PackageTemplate({ data, pageContext }: Props) {
  if (!data.sanityPackageLanding) return null;

  const { pageMeta, components, tiers } = data.sanityPackageLanding;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} type={pageContext.type} />
      {components &&
        components.map(component => {
          if (component == null) return null;

          if (isSanityTierGrid(component)) {
            return (
              <TierGrid key={component._key} content={component} type={pageContext.type} tiers={tiers} pagePath={pageContext.pagePath} />
            );
          }

          return (
            <Component key={component._key} data={component} type={pageContext.type} />
          );
        })
      }
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query PackageLandingQuery($slug: String) {
    sanityPackageLanding(pageMeta: {slug: {current: {eq: $slug}}}) {
      pageMeta {
        ...sanityPageMeta
      }
      components {
        ...sanityComponents
        ... on SanityTierGrid {
          ...sanityTierGrid
        }
      }
      tiers {
        ...sanityTier
      }
    }
  }
`;
